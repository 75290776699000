<template>
  <div class="container-price">
    <!-- <span class="price-value">R$ {{ valor }}</span> -->
    <span id="info-price">
      <i class="fa fa-plus" />
      <!-- <plus-icon
        style="stroke-width: 3"
        size="1x"
        class="custom-class"
      ></plus-icon> -->
      <span>Info</span>
    </span>
    <div class="price-item">
      <span>
        <small class="price-title">Valores Unitários</small>
      </span>
      <span class="price-st">
        <small class="price-title">ICMS/ST:</small>
        R$ {{ st }}
      </span>
      <span class="price-st">
        <small class="price-title">ICMS Desonerado:</small>
        R$ {{ stdesonerado }}
      </span>
      <span class="price-st">
        <small class="price-title">Produto:</small>
        R$ {{ produto }}
      </span>
      <span class="price-st">
        <small class="price-title">Desconto:</small>
        R$ {{ desconto }}
      </span>
      <span class="price-st">
        <small class="price-title">Total Unitário:</small>
        R$ {{ totalUnitario }}
      </span>
      <hr />
      <div class="emb-item">
        <span>
          <small class="price-title" style="display: flex">Embalagem</small>
        </span>
        <div v-if="embalagem">
          <small class="price-title">Emb. Cx. Fechada:</small>
          <span class="price-st">{{ embalagem }}</span>
        </div>
        <div v-if="embvenda">
          <small class="price-title">Emb. Venda:</small>
          <span class="price-st">{{ embvenda }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlusIcon from "vue-feather-icons/icons/PlusIcon";

export default {
  name: "ContainerPrice",
  components: {
    PlusIcon,
  },
  props: {
    // valor: {
    //   default: false
    // },
    st: {
      type: String,
      default: false,
    },
    produto: {
      type: String,
      default: false,
    },
    desconto: {
      type: String,
      default: false,
    },
    stdesonerado: {
      type: String,
      default: false,
    },
    totalUnitario: {
      default: "",
      type: String,
    },
    embalagem: {
      default: "",
      type: String,
    },
    embvenda: { default: "", type: String },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

@media (max-width: 768px) {
  .price-item {
    max-width: 275px !important;
    right: 0px !important;
  }
}

#info-price {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 50px;
}
.price-item {
  flex-direction: column !important;
}

.container-price .price-item small {
  font-weight: bold !important;
  font-size: 15px !important;
  color: #555 !important;
}

.bloco-1 {
  .produto-content {
    .produto-price-container {
      display: flex;
      flex-direction: column;

      .price-item {
        &:before {
          right: 50px;
        }
        display: flex;
        flex-direction: column;

        padding: 1px 1px 1px 0;

        small {
          font-size: 12px;
          font-weight: 600;
          color: #555555;
          margin-right: 3px;
        }

        .price-value,
        .price-old,
        .price-st {
          font-size: 12px;
          color: #555555;
        }

        .price-old {
          height: 17px;
        }

        span.price-value {
          font-size: 22px;
          font-weight: 700;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          color: rgb(51, 51, 51);
        }
      }
    }
  }
}

.bloco-2 {
  .produto-content {
    .produto-price-container {
      display: flex;
      flex-direction: column;

      .price-item {
        &:before {
          right: 50px;
        }

        display: flex;
        flex-direction: column;

        padding: 1px 1px 1px 0;

        .price-value,
        .price-old,
        .price-st {
          font-size: 12px;
          color: #555555;
        }
        .price-old {
          height: 17px;
        }
        span.price-value {
          font-size: 22px;
          font-weight: 700;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          color: rgb(51, 51, 51);
        }
      }
    }
  }
}

.busca-1 {
  .informacoes {
    .produto-price-container {
      display: none;
    }
  }
}

.lista-1 {
  .produto-price-container {
    display: flex;
    flex-direction: column;

    .price-item {
      &:before {
        right: 45px;
      }
      display: flex;
      flex-direction: column;

      padding: 1px 1px 1px 0;

      small {
        font-size: 12px;
        font-weight: 600;
        color: #555555;
        margin-right: 3px;
      }

      .price-value,
      .price-old,
      .price-st {
        font-size: 12px;
        color: #555555;
      }
      .price-old {
        height: 17px;
      }
      span.price-value {
        font-size: 22px;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        color: rgb(51, 51, 51);
      }
    }
  }
}

.lista-2 {
  width: 33%;
  float: left;
  padding: 5px !important;

  .informacoes {
    .produto-price-container {
      display: flex;
      flex-direction: column;

      .price-item {
        &:before {
          right: 50px;
        }

        display: flex;
        flex-direction: column;

        padding: 1px 1px 1px 0;

        small {
          font-size: 12px;
          font-weight: 600;
          color: #555555;
          margin-right: 3px;
        }

        .price-value,
        .price-old,
        .price-st {
          font-size: 12px;
          color: #555555;
        }
        .price-old {
          height: 17px;
        }
        span.price-value {
          font-size: 22px;
          font-weight: 700;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          color: rgb(51, 51, 51);
        }
      }
    }
  }
}

.container-price {
  display: initial;
  // position: relative;

  .price-item {
    position: absolute;
    background: #fff;
    border-radius: 4px;
    display: none !important;
    padding: 15px !important;
    width: 100%;
    left: 0;
    max-width: 300px;
    margin-top: 15px;
    z-index: 999;
    box-shadow: 0 0 15px #0000004a;

    &:hover,
    &:focus {
      display: flex !important;
    }

    hr {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 0;
      width: 100%;
      border-top: 1px solid #ccc;
      z-index: 999999999999999999;
    }

    small {
      font-weight: bold;
    }

    small,
    span {
      font-size: 15px !important;
    }
  }

  #info-price {
    cursor: pointer;
    min-width: 16px;
    height: 16px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    padding: 9px;
    margin: 0;
    background-color: #8d8d8d;
    color: white;
    font-size: 11.5px;
    font-weight: bold;

    .fa {
      font-size: 10px;
      margin: 0px 1px;
    }

    &:hover + .price-item {
      display: flex !important;
    }
  }
}

.slick-track {
  .container-price {
    .price-item {
      margin-top: -150px;
    }
  }
}
</style>
